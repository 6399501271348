@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Montserrat:wght@400;600;700&display=swap');

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat', sans-serif;
}

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@400;500;600;700&display=swap');

body {
  font-family: 'Nunito Sans', sans-serif;
}


/* Importing Poppins font from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

body {
  font-family: 'Poppins', sans-serif; /* Use Poppins as the primary font */
}

body {
  margin: 0;
  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @apply bg-slate-100; /* Tailwind utility for background color */
}

/* Code styling */
code {
  font-family: 'Source Code Pro', Menlo, Monaco, Consolas, 'Courier New', monospace;
}


.ql-container {
  max-height: none; /* Remove the maximum height restriction */
  overflow-y: auto; /* Enable vertical scrolling if content exceeds the container */
  overflow-x: hidden; /* Hide horizontal scroll */
}

.ql-editor {
  min-height: 200px; /* Set a minimum height for the editor */
  white-space: pre-wrap; /* Allow content to wrap to the next line */
}
